import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import Modal from 'components/Modal';
import AsyncButton from 'components/AsyncButton';
import LinkButton from 'components/LinkButton';
import InputGroup from './components/InputGroup';
import stepOneImg from 'assets/login_step_1.jpeg';
import stepTwoImg from 'assets/login_step_2.jpeg';
import stepThreeImg from 'assets/login_step_3.jpeg';

import {
  useAuth,
  useClient,
  useConfig,
  useLanguages,
  useLoggedIn,
} from 'utils/hooks';
import { useGAPList, useOEMUserList } from 'features/page/hooks';
import { useSupabase, supabase as getSupabase } from 'utils/supabase';
import isIE from 'utils/isIE';

import * as S from './styled';
import { AppContext } from 'components/App/Context';

import R2RSignupModal from 'features/R2RSignupModal';
import { GAP } from 'types';
import Select from 'components/Select';
import { otpResend, otpValidate } from 'utils/apis';
import { FSLoader } from 'features/page';

type State = {
  username: string;
  password: string;
  phone: string;
  otp: string;
};

function LoginModal({ close, client }: { close: Function; client: string }) {
  const [state, setState] = useState<State>({
    username: '',
    password: '',
    phone: '',
    otp: '',
  });
  const [authed, setAuthed] = useState<any | undefined>();
  const [verifystatus, setverifyStatus] = useState<'fetching' | 'done' | ''>(
    ''
  );
  const history = useHistory();
  const { messages } = useLanguages();
  const { defaults } = useConfig();
  const { doEmailLogin, doMagicLogin, OTPFetch, OTPConfirm, OTPSuccess } =
    useLoggedIn(true, true);
  const { authorise } = useAuth();
  const { fetchUser, list } = useOEMUserList();
  const supabase = useSupabase();
  const [status, setStatus] = useState<
    'done' | 'verifyPhone' | 'emailLogin' | ''
  >('emailLogin');
  const { setUser } = useContext(AppContext);
  const sb = getSupabase();
  const [gaps, setGaps] = useState<GAP[]>([]);
  const [selectedGap, setSelectedGap] = useState<any>();
  const [sUser, setSUser] = useState<any>();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const focusOnLoad = () => {
      const autoFocusedInput = document.getElementsByName('loginUserName');
      Array.from(autoFocusedInput)?.[0]?.focus();
    };
    focusOnLoad();
  }, []);

  const initLogin = async () => {
    console.log('here 2 :::::', state);
    if (!state.username) {
      toast(
        <FormattedMessage
          id="loginFailed"
          values={{ message: 'Missing username' }}
        />,
        { type: 'error' }
      );
      return;
    }
    setverifyStatus('fetching');
    const noAuth = await authorise(
      state.username,
      selectedGap ? parseInt(selectedGap) : 1
    );
    console.log('here 2 :::::', noAuth);
    // const [noAuth, data] = await Promise.all([fetchUser(state.username)]);
    // console.log('initLogin ::::', noAuth, data);
    if (noAuth?.authed?.error || noAuth?.error) {
      toast(
        <FormattedMessage
          id="loginFailed"
          values={{ message: noAuth?.authed?.error || noAuth?.error }}
        />,
        { type: 'error' }
      );
      setverifyStatus('done');
      return;
    }
    if (noAuth.message) {
      toast(
        <FormattedMessage
          id="authMessage"
          values={{ message: noAuth.message }}
        />,
        { type: 'info' }
      );
    }
    setAuthed(noAuth.authed);
    const data = await fetchUser(state.username, selectedGap);
    // const data = await fetchUser(state.username);
    console.log('data ::::', data);
    if (!data) {
      toast(
        <FormattedMessage id="loginFailed" values={{ message: 'No user' }} />,
        { type: 'error' }
      );
      setverifyStatus('done');
      return;
    }
    if (!data.mobilenumber) {
      // setStatus('emailLogin');
      setverifyStatus('done');
      await doLogin(noAuth.authed);
      return;
    }
    const { user, error: sError } = await supabase.auth.signIn({
      email: state.username,
      password: state.password,
    });
    if (sError) {
      toast(
        <FormattedMessage
          id="loginFailed"
          values={{ message: sError.message }}
        />,
        { type: 'error' }
      );
      setverifyStatus('done');
      return;
    }
    setSUser({ ...user, mobilenumber: data?.mobilenumber });
    const { error }: any = await otpResend(data.mobilenumber, state.username);
    if (error) {
      toast(<FormattedMessage id="loginFailed" values={{ message: error }} />, {
        type: 'error',
      });
      setverifyStatus('done');
      return;
    }
    setverifyStatus('done');
    setState({ ...state, phone: data.mobilenumber });
    setStatus('verifyPhone');
    toast(<FormattedMessage id="otpSent" />, { type: 'success' });
  };

  // const magicLink = async () => {
  //   await doMagicLogin(state.username);
  //   toast(<FormattedMessage id='magicLinkSent' />, { type: 'success' });
  // };

  const doLogin = async (au?: any) => {
    if (!state.username || !state.password) {
      toast(
        <FormattedMessage
          id="loginFailed"
          values={{ message: 'Missing username/password' }}
        />,
        { type: 'error' }
      );
      return;
    }
    setverifyStatus('fetching');
    console.log('here ::::', 1);
    if (status === 'emailLogin') {
      const error: any = await doEmailLogin(
        state.username,
        state.password,
        au?.authed === 'yes' || authed.authed === 'yes' ? client : 'r2r',
        selectedGap,
        au?.token || authed?.token
      );
      console.log('here ::::', error);
      if (!error) {
        list(state.username, selectedGap).then(() => {});
        history.push(
          au?.authed === 'yes' || authed.authed === 'yes'
            ? `/${client}`
            : `/${client}`
        );
        setUser(state.username);
        close();
      } else {
        toast(
          <FormattedMessage
            id="loginFailed"
            values={{ message: error.message }}
          />,
          { type: 'error' }
        );
      }
    } else {
      const { user, error }: any = await otpValidate(state.username, state.otp);
      if (!error) {
        list(state.username, selectedGap).then(() => {});
        console.log('token :::', authed);
        await OTPSuccess(
          sUser,
          state.username,
          au?.authed === 'yes' || authed.authed === 'yes' ? client : 'r2r',
          selectedGap,
          au?.token || authed?.token
        );
        history.push(
          au?.authed === 'yes' || authed.authed === 'yes'
            ? `/${client}`
            : `/${client}`
        );
        setUser(state.username);
        close();
      } else {
        toast(
          <FormattedMessage id="loginFailed" values={{ message: error }} />,
          { type: 'error' }
        );
      }
    }
    setverifyStatus('done');
  };

  const resetPassword = () => {
    // history.push('/resetPassword');
    if (!state.username) {
      toast(
        <FormattedMessage
          id="resetFailed"
          values={{ message: 'Email required' }}
        />,
        { type: 'error' }
      );
    }
    const { error }: any = supabase.auth.api.resetPasswordForEmail(
      state.username
    );
    if (error) {
      toast(
        <FormattedMessage
          id="resetFailed"
          values={{ message: error.message }}
        />,
        { type: 'error' }
      );
    } else {
      window.localStorage.setItem('resetpwd_client', client);
      toast(<FormattedMessage id="resetSent" />, { type: 'success' });
    }
  };

  const resendOTP = async () => {
    const { error }: any = await otpResend(sUser.mobilenumber, state.username);
    if (error) {
      toast(
        <FormattedMessage id="error" values={{ message: error.message }} />,
        { type: 'error' }
      );
    } else {
      toast(<FormattedMessage id="otpResendSuccess" />, { type: 'success' });
    }
  };

  const redirectSignUp = () => {
    // history.push('/auth/signup');
    // close();
    setShowLoginModal(true);
  };

  const dbGapUser = useMemo(
    () =>
      debounce(async (email) => {
        if (!email.includes('@')) return;
        if (defaults?.signuponlyindividual) {
          setSelectedGap(1);
          return;
        }
        setLoading(true);

        const resp: any = await sb
          .from('tb_gap')
          .select('*,tb_gap_users!inner(*)')
          .eq('tb_gap_users.userid', email)
          .eq('tb_gap_users.oemid', defaults?.oemid);

        setGaps(
          resp?.data?.map((x) => ({
            ...x,
            gapdetails: `${x?.gapid} - ${x.gapcompanyname} (ABN: ${x.abn}) ${x.street1} ${x.city} ${x.state} ${x.postcode}`,
          }))
        );
        if (resp?.data?.length === 1) {
          setSelectedGap(resp?.data?.[0]?.gapid);
        }

        setLoading(false);
      }, 750),
    [sb, defaults]
  );

  if (showLoginModal) {
    return (
      <R2RSignupModal
        client={client}
        page={2}
        image={defaults?.loginmodalimg}
        close={() => setShowLoginModal(false)}
      />
    );
  }

  return (
    <Modal close={close} overflow="auto">
      <S.Container>
        <Helmet>
          <title>{messages.login}</title>
        </Helmet>
        {loading && <FSLoader which="gap_list" />}
        {/* <h2 style={{ fontSize: '3em' }}>{messages.r2rLogin.toUpperCase()}</h2> */}
        {status === 'verifyPhone' ? (
          <S.GuideImg src={stepThreeImg} />
        ) : state.username ? (
          <S.GuideImg src={stepTwoImg} />
        ) : (
          <S.GuideImg src={stepOneImg} />
        )}
        <S.Content>
          <S.LeftSection $hasGap={gaps?.length > 0}>
            <h2 style={{ fontSize: '2em', marginTop: '1em' }}>
              {messages.r2rLogin}
            </h2>
            <form
              onSubmit={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log('HERE ******');
                if (['verifyPhone'].includes(status)) {
                  // setUser(state.username);
                  setTimeout(doLogin, 1000);
                } else {
                  // setUser(state.username);
                  setTimeout(initLogin, 1000);
                }
              }}
              style={{ width: '80%' }}
            >
              <S.AlignerContainer style={{ width: '100%' }}>
                <div className="flex flex-col gap-6" style={{ width: '100%' }}>
                  <InputGroup
                    autoFocus
                    name="loginUserName"
                    value={state.username}
                    iconName="user"
                    placeholder="Username"
                    onChange={(value: string) => {
                      setState({ ...state, username: value || '' });
                      dbGapUser(value);
                    }}
                    onBlur={() => dbGapUser(state.username)}
                  />
                  {gaps?.length > 1 && (
                    <Select
                      containerStyle={{
                        width: '100%',
                        paddingLeft: '0',
                        marginLeft: '0',
                      }}
                      placeholder="Gateway Access Provider (Subscription holder)"
                      data={gaps}
                      idKey="gapid"
                      valueKey="gapdetails"
                      value={parseInt(selectedGap)}
                      withNullOption
                      onChange={(e) => {
                        console.log(e);
                        setSelectedGap(
                          Number(e.target.value)
                            ? parseInt(e.target.value)
                            : null
                        );
                      }}
                    />
                  )}
                  {['emailLogin'].includes(status) && (
                    <InputGroup
                      value={state.password}
                      iconName="lock"
                      placeholder="Password"
                      password={true}
                      onChange={(value: string) =>
                        setState({ ...state, password: value || '' })
                      }
                    />
                  )}
                  {['verifyPhone'].includes(status) && (
                    <InputGroup
                      value={state.otp}
                      iconName="mobile"
                      placeholder={'One time pin (OTP)'}
                      // password
                      onChange={(value: string) =>
                        setState({ ...state, otp: value || '' })
                      }
                    />
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row">
                    {status === 'verifyPhone' && (
                      <>
                        <AsyncButton
                          type="button"
                          disabled={verifystatus === 'fetching'}
                          messageId={'resendOTP'}
                          onClick={resendOTP}
                        />
                        <span
                          style={{ width: '2rem', display: 'inline-block' }}
                        />
                      </>
                    )}
                    <AsyncButton
                      type="submit"
                      disabled={verifystatus === 'fetching'}
                      messageId={
                        verifystatus === 'fetching'
                          ? 'verifying'
                          : status === 'verifyPhone'
                          ? 'verify'
                          : 'authenticate'
                      }
                    />
                  </div>
                  <div className="flex justify-between">
                    <LinkButton
                      onClick={() => {
                        redirectSignUp();
                      }}
                      messageId="Signup"
                    ></LinkButton>
                    {showLoginModal && (
                      <>
                        <R2RSignupModal
                          client={client}
                          page={2}
                          image={defaults?.loginmodalimg}
                          close={() => setShowLoginModal(false)}
                        />
                      </>
                    )}
                    <LinkButton
                      onClick={resetPassword}
                      messageId="resetPassword"
                    />
                  </div>
                </div>
              </S.AlignerContainer>
            </form>
          </S.LeftSection>
          <S.RightSection>
            {isIE() ? (
              <S.ImageIE src={defaults?.loginmodalimg} />
            ) : (
              <S.Image src={defaults?.loginmodalimg} />
            )}
          </S.RightSection>
        </S.Content>
      </S.Container>
    </Modal>
  );
}

export default LoginModal;
