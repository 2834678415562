import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useState, useRef, useEffect, useContext, ReactElement } from 'react';

import {
  useLoggedIn,
  useAssetsNav,
  useBulletinsNav,
  useToast,
  useConfig,
  useAnalytics,
} from 'utils/hooks';
import { useSupabase } from 'utils/supabase';
import { account, clipEmail } from 'utils/apis';
import { blobToBase64 } from 'utils/functions';
import { useAssetCategories } from 'features/assets/hooks';
import { LanguageContext } from 'components/Language/Context';
import AssetMenuModals from '../../features/AssetMenuModals';
import MyAccountModal from 'features/MyAccountModal';
import StripePayment from 'features/StripePayment';
import { MyAccountData, AssetCategory, OEMList } from 'types';
import { useOEMList, useOEMUserList } from 'features/page/hooks';
import { useOEM } from 'features/OEM/hooks';
import Modal from 'components/Modal';
import PDFViewer from 'features/page/components/PDFViewer';
import AssetCatRenderer from './subcomponents/AssetCatRenderer';
import { Link } from './styled';
import { FSLoader } from 'features/page';
import { StringLiteralType } from 'typescript';
import { AppContext } from 'components/App/Context';
import LinkButton from 'components/LinkButton';

const Container = styled.div<{ $show: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 25em;
  right: ${(props) => (props.$show ? '0' : '-26em')};
  transition: right linear 200ms;
  z-index: 50;
  background: #fff;
  color: black;
  padding: 1em;
  box-shadow: -5px 2px 10px rgb(0 0 0 / 20%);
  overflow-x: scroll;
  li {
    padding: 8px;
    font-family: 'mmcregular', sans-serif;
    font-size: 16px;
  }
`;

const SubHead = styled.li<any>`
  color: ${(props) => props.$data?.primarycolour || '#e90000'};
  text-decoration: underline;
`;

const ALink = styled.a<any>`
  :hover {
    color: ${(props) => props.$data?.primarycolour || '#e90000'};
  }
`;

const Icon = styled.span<any>`
  color: darkgrey;
`;

type MenuProps = {
  hasOemAdmin?: boolean;
  hasGapAdmin?: number;
  hasAccessProfiles?: boolean;
  hasAssets?: boolean;
  hasAdmin?: boolean;
  showMenu: boolean;
  setShowMenu: Function;
  client: string;
  messages: any;
  doLogout: any;
  languages?: any;
  languageId?: number;
  defaults: any;
  openModal?: any;
  openMyAccount?: any;
  openStripePaymentModal?: any;
  user?: any;
  hasClientAdmin?: boolean;
  hasUsersData?: boolean;
  hasPortalAccess?: boolean;
  data?: any;
};

const LinkItem = ({
  icon,
  setShowMenu,
  client,
  link,
  messages,
  label,
  onClick,
}: {
  icon: string;
  setShowMenu: Function;
  client: string;
  link?: string;
  messages: any;
  label: string;
  onClick?: Function;
}) => {
  const { defaults } = useConfig();
  const { menu } = useAnalytics();

  const Component: any = onClick ? LinkButton : Link;

  return (
    <li>
      <Icon className={`fas ${icon}`} />
      &nbsp;&nbsp;
      <Component
        $client={client}
        $data={defaults}
        onClick={async () => {
          setShowMenu();
          onClick ? onClick() : menu(label, undefined);
        }}
        $color="darkblue"
        messageId={label}
        to={(location) => ({ ...location, pathname: `/${client}${link}` })}
      >
        {messages?.[label] || 'DNF'}
      </Component>
    </li>
  );
};

const AdminMenu = ({
  showMenu,
  hasAdmin,
  hasOemAdmin,
  hasGapAdmin,
  hasClientAdmin,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
  user,
}: MenuProps) => (
  <>
    <button onClick={setShowMenu as any} className="float-right">
      <Icon className={'fas fa-times'} size="sm" />
    </button>
    <ul>
      <LinkItem
        icon="fa-home"
        setShowMenu={setShowMenu}
        client={client}
        link=""
        messages={messages}
        label="home"
      />
      {(hasAdmin || hasClientAdmin) && client !== 'r2r' && (
        <>
          <SubHead $data={defaults}>{messages.ModelItemMaintenance}</SubHead>
          <LinkItem
            icon="fa-building"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/oems"
            messages={messages}
            label="oems"
          />
          <LinkItem
            icon="fa-globe"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/divisions"
            messages={messages}
            label="divisions"
          />
          <LinkItem
            icon="fa-copyright"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/brands"
            messages={messages}
            label="brands"
          />
          <LinkItem
            icon="fa-user-cog"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/modelFamily"
            messages={messages}
            label="modelFamily"
          />
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/modelSeries"
            messages={messages}
            label="modelSeries"
          />
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/model"
            messages={messages}
            label="model"
          />
          <LinkItem
            icon="fa-calendar"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/modelYear"
            messages={messages}
            label="modelYear"
          />
          <LinkItem
            icon="fa-calendar"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/productionYear"
            messages={messages}
            label="productionYear"
          />
          <SubHead $data={defaults}>
            {messages.repairAreaItemMaintenance}
          </SubHead>
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/repairGroup"
            messages={messages}
            label="repairGroup"
          />
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/repairSubGroup"
            messages={messages}
            label="repairSubGroup"
          />
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/repairComponent"
            messages={messages}
            label="repairComponent"
          />
          <SubHead $data={defaults}>{messages.AssetItemMaintenance}</SubHead>
          <LinkItem
            icon="fa-car"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/assetCategory"
            messages={messages}
            label="assetCats"
          />
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/assetType"
            messages={messages}
            label="assetType"
          />
          <SubHead $data={defaults}>
            {messages.assetAccessItemMaintenance}
          </SubHead>
          <LinkItem
            icon="fa-list"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/aasraProfile"
            messages={messages}
            label="aasraProfile"
          />
          <SubHead $data={defaults}>{messages.AssetOnboarding}</SubHead>
          <LinkItem
            icon="fa-car"
            setShowMenu={setShowMenu}
            client={client}
            link="/admin/maintenance/assets"
            messages={messages}
            label="assets"
          />
        </>
      )}

      {(hasGapAdmin || hasOemAdmin) && (
        <>
          <SubHead $data={defaults}>{messages.adminData}</SubHead>
          {hasOemAdmin && (
            <>
              <LinkItem
                icon="fa-cog"
                setShowMenu={setShowMenu}
                client={client}
                link="/admin/maintenance/oem"
                messages={messages}
                label="oemAdmin"
              />
            </>
          )}
          {hasGapAdmin && (
            <LinkItem
              icon="fa-cog"
              setShowMenu={setShowMenu}
              client={client}
              link="/admin/maintenance/gap"
              messages={messages}
              label="gapAdmin"
            />
          )}
          {[
            'felipe.lin2018@hotmail.com',
            'john.bowden@encollab.com',
            'kingsley.foote@fusioniii.com',
          ].includes(user?.email) &&
            hasOemAdmin && (
              <>
                <LinkItem
                  icon="fa-list"
                  setShowMenu={setShowMenu}
                  client={client}
                  link="/admin/maintenance/gaps"
                  messages={messages}
                  label="gaps"
                />
                <LinkItem
                  icon="fa-list"
                  setShowMenu={setShowMenu}
                  client={client}
                  link="/admin/maintenance/subscriptions"
                  messages={messages}
                  label="subscriptions"
                />
              </>
            )}
        </>
      )}
      <li>
        <Icon className={'fas fa-sign-out'} />
        &nbsp;&nbsp;
        <Link
          $client={client}
          $data={defaults}
          onClick={() => doLogout('menu')}
          to={'#'}
        >
          {messages.logout}
        </Link>
      </li>
    </ul>
  </>
);

const AccessMenu = ({
  showMenu,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
}: MenuProps) => (
  <>
    <button onClick={setShowMenu as any} className="float-right">
      <Icon className={'fas fa-times'} size="sm" />
    </button>
    <ul>
      <LinkItem
        icon="fa-home"
        setShowMenu={setShowMenu}
        client={client}
        link=""
        messages={messages}
        label="home"
      />
      <LinkItem
        icon="fa-car"
        setShowMenu={setShowMenu}
        client={client}
        link="/access/maintenance/assets"
        messages={messages}
        label="assetProfiles"
      />
      <li>
        <Icon className={'fas fa-sign-out'} />
        &nbsp;&nbsp;
        <Link
          $client={client}
          $data={defaults}
          onClick={() => doLogout('menu')}
          to={'#'}
        >
          {messages.logout}
        </Link>
      </li>
    </ul>
  </>
);

const RootMenu = ({
  hasOemAdmin,
  hasGapAdmin,
  hasAccessProfiles,
  hasAdmin,
  hasAssets,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
}: MenuProps) => (
  <>
    <button onClick={setShowMenu as any} className="float-right">
      <Icon className={'fas fa-times'} size="sm" />
    </button>
    <ul>
      <LinkItem
        icon="fa-home"
        setShowMenu={setShowMenu}
        client={client}
        link=""
        messages={messages}
        label="home"
      />
      {/* {client === 'mmal' && (
        <>
          <li>
            <Icon className={'fas fa-car'} />
            &nbsp;&nbsp;
            <ALink
              href="https://as2au-dev.exnet.mitsubishi-motors.com/testapp"
              target="blank"
            >
              SAML Test App
            </ALink>
          </li>
          <li>
            <Icon className={'fas fa-car'} />
            &nbsp;&nbsp;
            <ALink
              href="https://as2au-dev.exnet.mitsubishi-motors.com/testapp2"
              target="blank"
            >
              SAML Test App 2
            </ALink>
          </li>
        </>
      )} */}
      {/* <a href="/mmal/urlAuthenticator">Asset URL Authenticator</a> */}
      {((hasAdmin && client !== 'r2r') ||
        (hasOemAdmin && client !== 'r2r') ||
        (hasGapAdmin && client === 'r2r')) && (
        <LinkItem
          icon="fa-car"
          setShowMenu={setShowMenu}
          client={client}
          link="/admin"
          messages={messages}
          label="maintenance"
        />
      )}
      {hasAssets && client !== 'r2r' && (
        <LinkItem
          icon="fa-list"
          setShowMenu={setShowMenu}
          client={client}
          link="/assets"
          messages={messages}
          label="assetsSearch"
        />
      )}
      {hasAccessProfiles && client !== 'r2r' && (
        <LinkItem
          icon="fa-cog"
          setShowMenu={setShowMenu}
          client={client}
          link="/access"
          messages={messages}
          label="accessProfiles"
        />
      )}
      <li>
        <Icon className={'fas fa-sign-out'} />
        &nbsp;&nbsp;
        <Link
          $client={client}
          $data={defaults}
          onClick={() => doLogout('menu')}
          to={'#'}
        >
          {messages.logout}
        </Link>
      </li>
    </ul>
  </>
);

const R2RMenu = ({ setShowMenu, defaults }: MenuProps) => {
  const { data }: { data: OEMList[] | undefined } = useOEMList();

  return (
    <>
      <button onClick={setShowMenu as any} className="float-right">
        <Icon className={'fas fa-times'} size="sm" />
      </button>
      <ul>
        {data
          ?.slice()
          ?.sort(({ id: a }, { id: b }) => a - b)
          ?.map((item, index) => {
            if (item.hidemenu || item.path === 'r2r' || item.path === 'ineos')
              return null;
            return (
              // if item.path is not r2r and ineos then show the li
              <li key={index}>
                <img
                  src={item.homepagelisticon}
                  style={{ display: 'inline', position: 'relative' }}
                  className="w-10"
                />
                &nbsp;&nbsp;
                <Link
                  $data={{ ...defaults, fontcolor: 'black' }}
                  onClick={() => {
                    setShowMenu();
                    try {
                      (window as any).updateChatbot(item.path);
                    } catch (e) {}
                  }}
                  to={(location) => ({
                    ...location,
                    pathname: item.path ? `/${item.path}` : '/',
                  })}
                >
                  {item.Name}
                </Link>
              </li>
            );
          })}
      </ul>
    </>
  );
};

const AssetsMenu = ({
  showMenu,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
  languages,
  languageId,
  openModal,
  openMyAccount,
  openStripePaymentModal,
  data,
}: MenuProps) => {
  const { data: assetCategories, findDataId } = useAssetCategories();
  const history = useAssetsNav();
  const bulletins = useBulletinsNav();
  const [sortedData, setSortedData] = useState<AssetCategory[]>();
  const { downloadFile } = useSupabase();
  const [showPdf, setShowPdf] = useState<string | null>(null);
  const { menu } = useAnalytics();
  const { user } = useLoggedIn();
  const { userSubs } = useOEMUserList();
  const historyPush = useHistory();

  useEffect(() => {
    console.log('assetCategories');
    console.log(assetCategories);
    if (!assetCategories || sortedData) return;
    const list = assetCategories.map((d) => {
      const a = findDataId(d.assetcategoryid);
      return { ...d, assetcategoryorder: a?.assetcategoryorder };
    });
    setSortedData(
      list.sort((a, b) => a.assetcategoryorder - b.assetcategoryorder)
    );
    /* eslint-disable-next-line */
  }, [assetCategories, sortedData]);

  return (
    <>
      <button onClick={setShowMenu as any} className="float-right">
        <Icon className={'fas fa-times'} size="sm" />
      </button>
      <ul>
        <li>
          <Icon className={'fas fa-home'} />
          &nbsp;&nbsp;
          <Link
            $client={client}
            $data={defaults}
            onClick={setShowMenu as any}
            to={(location) => ({ ...location, pathname: `/${client}` })}
          >
            {messages.home}
          </Link>
        </li>
        {sortedData
          ?.filter(({ languageid }) => languageid === languageId)
          ?.map((assetCat: AssetCategory, index: number) => {
            const isHeading = assetCat?.assetcategorydescription.includes('->');
            console.log(
              assetCat?.assetcategorydescription + '===>' + assetCat?.disabled
            );
            const isDev =
              window.location.host.includes('dev') ||
              window.location.host.includes('localhost');
            let assetCategory = assetCat.assetcategorydescription;
            // if (assetCat?.disabled && !isDev) return null; // Use in DEV
            if (assetCat?.disabled) return null; // Use in DEV
            if (
              client === 'mmal' &&
              [
                'special tools',
                'mut software info',
                'clip software info',
              ].includes(assetCategory.toLowerCase())
            )
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={(assetCategory?) => openModal(assetCategory)}
                />
              );

            if (
              client != 'ineos' &&
              ['security', 'safety'].includes(assetCategory.toLowerCase())
            )
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={(assetCategory?) => openModal(assetCategory)}
                />
              );
            if (assetCategory === 'My Account') {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={openMyAccount}
                />
              );
            }

            if (
              assetCategory === 'Diagnostic Software' &&
              client === 'deepal' &&
              data?.currentaasrarole?.includes('ev')
            ) {
              console.log('HERE ********', client, data?.currentaasrarole);
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={() =>
                    openStripePaymentModal(
                      'diagnostic',
                      userSubs,
                      setShowMenu,
                      historyPush,
                      client
                    )
                  }
                />
              );
            } else if (
              assetCategory === 'Diagnostic Software' &&
              client === 'deepal'
            )
              return null;

            if (
              assetCategory === 'Diagnostic Software' &&
              client !== 'deepal'
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={() =>
                    openStripePaymentModal(
                      'diagnostic',
                      userSubs,
                      setShowMenu,
                      historyPush,
                      client
                    )
                  }
                />
              );
            }

            if (
              assetCategory === 'Diagnostic Hardware' &&
              client === 'deepal' &&
              data?.currentaasrarole?.includes('ev')
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={() =>
                    openStripePaymentModal(
                      'diagnostic_hardware',
                      userSubs,
                      setShowMenu,
                      historyPush,
                      client
                    )
                  }
                />
              );
            } else if (
              assetCategory === 'Diagnostic Hardware' &&
              client === 'deepal'
            )
              return null;

            if (
              assetCategory === 'Diagnostic Hardware' &&
              client !== 'deepal'
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={() =>
                    openStripePaymentModal(
                      'diagnostic_hardware',
                      userSubs,
                      setShowMenu,
                      historyPush,
                      client
                    )
                  }
                />
              );
            }

            if (
              client === 'mmal' &&
              [
                'hotline fix',
                'quick reference',
                'service bulletin',
                'service campaign',
                'recall campaign',
                'published tsr',
              ].includes(assetCategory.toLowerCase())
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={() => {
                    setShowMenu(false);
                    bulletins.push(
                      client,
                      assetCat.assetcategorydescription
                        // .toLocaleLowerCase()
                        .replaceAll(' ', '-'),
                      assetCategory.includes('TSR') ? 'tsr' : undefined
                    );
                  }}
                />
              );
            }

            if (
              (client === 'mmal' &&
                ['news'].includes(assetCategory.toLowerCase())) ||
              ((client === 'subaru' || client === 'deepal') &&
                ['help'].includes(assetCategory.toLowerCase()))
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  onClick={async () => {
                    // setShowMenu(false);
                    console.log('PDF :::::', defaults?.helppdf);
                    const data = await downloadFile(
                      defaults?.[assetCategory.toLowerCase()],
                      defaults.oemid
                    );
                    const str = await blobToBase64(data?.data);
                    setShowPdf(str);
                  }}
                />
              );
            }

            if (
              (client === 'subaru' || client === 'deepal') &&
              ['request key code'].includes(assetCategory.toLowerCase())
            ) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  to={`/${client}/assets/request/key`}
                  onClick={async () => {
                    setShowMenu(false);
                  }}
                />
              );
            }

            // if (
            //   client === 'subaru' &&
            //   ['software update'].includes(assetCategory.toLowerCase())
            // ) {
            //   return (
            //     <AssetCatRenderer
            //       key={index}
            //       assetCat={assetCat}
            //       assetCategory={assetCategory}
            //       client={client}
            //       defaults={defaults}
            //       index={index}
            //       to={`/${client}/assets/request/software`}
            //       onClick={async () => {
            //         setShowMenu(false);
            //       }}
            //     />
            //   );
            // }

            if (
              (client === 'subaru' || client === 'deepal') &&
              ['diagnostic hardware | software'].includes(
                assetCategory.toLowerCase()
              )
            ) {
              return (
                // <AssetCatRenderer
                //   key={index}
                //   assetCat={assetCat}
                //   assetCategory={assetCategory}
                //   client={client}
                //   defaults={defaults}
                //   index={index}
                //   to={`/${client}/assets/request/software`}
                //   onClick={async () => {
                //     setShowMenu(false);
                //   }}
                // />
                <LinkItem
                  icon="fa-key"
                  setShowMenu={setShowMenu}
                  client={client}
                  messages={messages}
                  label="diagnostic software | hardware"
                  onClick={() =>
                    openStripePaymentModal(
                      'diagnostic',
                      userSubs,
                      setShowMenu,
                      historyPush,
                      client
                    )
                  }
                />
              );
            }

            if (['vin search'].includes(assetCategory.toLowerCase())) {
              return (
                <AssetCatRenderer
                  key={index}
                  assetCat={assetCat}
                  assetCategory={assetCategory}
                  client={client}
                  defaults={defaults}
                  index={index}
                  to={`/${client}/assets/vinsearch`}
                  onClick={async () => {
                    setShowMenu(false);
                  }}
                />
              );
            }

            if (isHeading) {
              return (
                <li key={index}>
                  <b>
                    {assetCat?.assetcategorydescription
                      .replace('->', '')
                      .trim()}
                  </b>
                </li>
              );
            }

            return (
              <li key={index} style={{ whiteSpace: 'nowrap' }}>
                {assetCat?.assetcategoryimageurlthumbnail ||
                  (assetCat?.assetcategoryimageurl && (
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                        display: 'inline',
                      }}
                      src={
                        assetCat?.assetcategoryimageurlthumbnail ||
                        assetCat?.assetcategoryimageurl
                      }
                    />
                  ))}
                &nbsp;&nbsp;
                <Link
                  $client={client}
                  $data={defaults}
                  onClick={() => {
                    menu(
                      assetCat.assetcategorydescription,
                      assetCat.assetcategoryid
                    );
                    setShowMenu();
                    history.push(
                      client,
                      assetCat.assetcategorydescription
                        .toLocaleLowerCase()
                        .replaceAll(' ', '-')
                    );
                  }}
                  to={'#'}
                >
                  {assetCat.assetcategorydescription}
                </Link>
              </li>
            );
          })}
        {/* {(user.email === 'j.j.b1980@live.com' ||
          user.email === 'john.bowden@encollab.com') && (
          <li>
            &nbsp;&nbsp;
            <Link
              $client={client}
              $data={defaults}
              onClick={() => setShowMenu()}
              to={`/${client}/assets/request/software`}
            >
              {messages.softwareUpdate}
            </Link>
          </li>
        )} */}
        <li>
          <Icon className={'fas fa-sign-out'} />
          &nbsp;&nbsp;
          <Link
            $client={client}
            $data={defaults}
            onClick={() => doLogout('menu')}
            to={'#'}
          >
            {messages.logout}
          </Link>
        </li>
      </ul>
      {showPdf !== null && (
        <Modal
          style={{
            width: 'auto',
            height: 'auto',
            overflow: 'auto',
            padding: '0',
          }}
          close={() => setShowPdf(null)}
          closeXPosition="30px"
          closeYPosition="25px"
        >
          <PDFViewer
            path={showPdf}
            noWatermark
            canvas={'width: 100% !important; height: 70% !important;'}
          />
        </Modal>
      )}
    </>
  );
};

const SupportMenu = ({
  showMenu,
  setShowMenu,
  client,
  hasPortalAccess,
  hasUsersData,
  messages,
  doLogout,
  defaults,
  hasOemAdmin,
}: MenuProps) => (
  <>
    <button onClick={setShowMenu as any} className="float-right">
      <Icon className={'fas fa-times'} size="sm" />
    </button>
    <ul>
      <LinkItem
        icon="fa-home"
        setShowMenu={setShowMenu}
        client={client}
        link=""
        messages={messages}
        label="home"
      />
      <LinkItem
        icon="fa-car"
        setShowMenu={setShowMenu}
        client={client}
        link="/admin/maintenance/assets"
        messages={messages}
        label="assets"
      />
      <LinkItem
        icon="fa-table"
        setShowMenu={setShowMenu}
        client={client}
        link="/support/activesubs"
        messages={messages}
        label="activeSubs"
      />
      <LinkItem
        icon="fa-table"
        setShowMenu={setShowMenu}
        client={client}
        link="/support/gaps"
        messages={messages}
        label="gaps"
      />
      {hasPortalAccess && (
        <LinkItem
          icon="fa-table"
          setShowMenu={setShowMenu}
          client={client}
          link="/support/portal"
          messages={messages}
          label="portalAccess"
        />
      )}
      {hasUsersData && (
        <LinkItem
          icon="fa-table"
          setShowMenu={setShowMenu}
          client={client}
          link="/support/usersdata"
          messages={messages}
          label="hasUsersData"
        />
      )}
      {hasPortalAccess &&
        (client === 'honda' || client === 'subaru' || client === 'deepal') && (
          <LinkItem
            icon="fa-table"
            setShowMenu={setShowMenu}
            client={client}
            link="/support/diagnostic"
            messages={messages}
            label="hasDSWData"
          />
        )}
      {(client === 'mmal' ||
        client === 'honda' ||
        client === 'subaru' ||
        client === 'deepal') && (
        <LinkItem
          icon="fa-car"
          setShowMenu={setShowMenu}
          client={client}
          link="/support/dashboard"
          messages={messages}
          label="dashboard"
        />
      )}
      {hasOemAdmin && (
        <LinkItem
          icon="fa-cog"
          setShowMenu={setShowMenu}
          client={client}
          link="/support/clientoemadmin"
          messages={messages}
          label="oemAdmin"
        />
      )}

      <li>
        <Icon className={'fas fa-sign-out'} />
        &nbsp;&nbsp;
        <Link
          $client={client}
          $data={defaults}
          onClick={() => doLogout('menu')}
          to={'#'}
        >
          {messages.logout}
        </Link>
      </li>
    </ul>
  </>
);

const NoSubMenu = ({
  showMenu,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
  user,
  openStripePaymentModal,
  data,
}: MenuProps) => {
  let menuItems: ReactElement[] = [];
  const { userSubs } = useOEMUserList();
  const historyPush = useHistory();

  if (client === 'subaru') {
    menuItems = [
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        link={'/assets/request/key'}
        messages={messages}
        label="request key code"
      />,
      <LinkItem
        icon="fa-code"
        setShowMenu={setShowMenu}
        client={client}
        link={'/assets/request/software'}
        messages={messages}
        label="softwareUpdate"
      />,
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        messages={messages}
        label="Diagnostic Software"
        onClick={() =>
          openStripePaymentModal(
            'diagnostic',
            userSubs,
            setShowMenu,
            historyPush,
            client
          )
        }
      />,
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        messages={messages}
        label="Diagnostic Hardware"
        onClick={() =>
          openStripePaymentModal(
            'diagnostic_hardware',
            userSubs,
            setShowMenu,
            historyPush,
            client
          )
        }
      />,
    ];
  }

  if (client === 'honda') {
    menuItems = [
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        messages={messages}
        label="diagnostic software"
        onClick={() =>
          openStripePaymentModal(
            'diagnostic',
            userSubs,
            setShowMenu,
            historyPush,
            client
          )
        }
      />,
    ];
  }

  if (client === 'deepal' && data?.currentaasrarole?.includes('ev')) {
    menuItems = [
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        messages={messages}
        label="diagnostic software"
        onClick={() =>
          openStripePaymentModal(
            'diagnostic',
            userSubs,
            setShowMenu,
            historyPush,
            client
          )
        }
      />,
      <LinkItem
        icon="fa-key"
        setShowMenu={setShowMenu}
        client={client}
        messages={messages}
        label="diagnostic hardware"
        onClick={() =>
          openStripePaymentModal(
            'diagnostic_hardware',
            userSubs,
            setShowMenu,
            historyPush,
            client
          )
        }
      />,
    ];
  }

  return (
    <ul>
      <LinkItem
        icon="fa-home"
        setShowMenu={setShowMenu}
        client={client}
        link=""
        messages={messages}
        label="home"
      />
      {menuItems}
      <li>
        <Icon className={'fas fa-sign-out'} />
        &nbsp;&nbsp;
        <Link
          $client={client}
          $data={defaults}
          onClick={() => doLogout('menu')}
          to={'#'}
        >
          {messages.logout}
        </Link>
      </li>
    </ul>
  );
};

const AdministratorMenu = ({
  showMenu,
  setShowMenu,
  client,
  messages,
  doLogout,
  defaults,
  user,
}: MenuProps) => {
  let hasOemAdmin = false;
  const cfg = defaults?.oemadminconfig;
  if (cfg) {
    const users = cfg.split('|')[0];
    if (users.includes(user?.email)) hasOemAdmin = true;
  }
  return (
    <>
      <button onClick={setShowMenu as any} className="float-right">
        <Icon className={'fas fa-times'} size="sm" />
      </button>
      <ul>
        <LinkItem
          icon="fa-home"
          setShowMenu={setShowMenu}
          client={client}
          link=""
          messages={messages}
          label="home"
        />
        <LinkItem
          icon="fa-table"
          setShowMenu={setShowMenu}
          client={client}
          link="/administrator/activesubs"
          messages={messages}
          label="activeSubs"
        />
        <LinkItem
          icon="fa-car"
          setShowMenu={setShowMenu}
          client={client}
          link="/admin/maintenance/assets"
          messages={messages}
          label="assets"
        />
        {hasOemAdmin && (
          <LinkItem
            icon="fa-cog"
            setShowMenu={setShowMenu}
            client={client}
            link="/support/clientoemadmin"
            messages={messages}
            label="oemAdmin"
          />
        )}
        {(client === 'mmal' || client === 'honda' || client === 'subaru') && (
          <LinkItem
            icon="fa-car"
            setShowMenu={setShowMenu}
            client={client}
            link="/support/dashboard"
            messages={messages}
            label="dashboard"
          />
        )}
        <li>
          <Icon className={'fas fa-sign-out'} />
          &nbsp;&nbsp;
          <Link
            $client={client}
            $data={defaults}
            onClick={() => doLogout('menu')}
            to={'#'}
          >
            {messages.logout}
          </Link>
        </li>
      </ul>
    </>
  );
};

function Menu({
  showMenu,
  setShowMenu,
  messages,
}: {
  showMenu: boolean;
  setShowMenu: Function;
  messages: any;
}) {
  const ref: any = useRef(null);
  const { doLogout, user } = useLoggedIn(true, true);
  const { defaults } = useConfig();
  const { locales: languages, languageId } = useContext(LanguageContext);
  const { noSubSignin } = useContext(AppContext);
  const location = useLocation();
  const {
    hasAdmin,
    hasAssets,
    hasAccessProfiles,
    hasOemAdmin,
    hasGapAdmin,
    hasSupport,
    hasClientAdmin,
    hasUsersData,
    hasPortalAccess,
    data,
  } = useOEMUserList();
  const auth: any = sessionStorage.getItem('auth');
  const store = JSON.parse(auth || '{}');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [showStripePayment, setShowStripePaymemt] = useState<
    undefined | 'diagnostic' | 'keycode'
  >();
  const [status, setStatus] = useState<'done' | 'fetching' | ''>('');
  const [lastClip, setLastClip] = useState<number>();
  const { toast } = useToast();
  const {
    openMyAccount,
    closeMyAccount,
    fromTable,
    showAccount,
    myAccountData,
  } = useContext(AppContext);

  const split = window.location.pathname.split('/');
  const client = split[1];
  const zone = split[2];
  const sub = split[3];

  useEffect(() => {
    const handleClose = (e) => {
      if (e?.srcElement?.className === 'top-menu') return;
      if (ref.current && ref.current.contains(e.target)) return;
      if (showMenu) setShowMenu(false);
    };
    window.document.addEventListener('click', handleClose);
    const ifr: any = window.document.getElementById('iframe-content');
    if (ifr?.contentWindow)
      ifr.contentWindow.addEventListener('click', handleClose);
    return () => {
      window.document.removeEventListener('click', handleClose);
      if (ifr?.contentWindow)
        ifr.contentWindow.removeEventListener('click', handleClose);
    };
  }, [setShowMenu, showMenu]);

  const openModal = (assetCategoryDescription: string) => {
    setModalType(assetCategoryDescription);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openStripePaymentModal = async (
    type: any,
    userSubs: any,
    setShowMenu?: any,
    history?: any,
    client?: string
  ) => {
    console.log({ userSubs });
    if (
      type === 'diagnostic' &&
      (userSubs?.find((x) => x.subType === 'diagnostic') ||
        userSubs?.find((x) => x.subType === 'diagnostic_software'))
    ) {
      setShowMenu(false);
      history.push(`/${client}/assets/find/diagnostic-software`);
      return;
    }
    setShowStripePaymemt(type);
  };

  // const closeMyAccount = () => {
  //   setShowMyAccount(false);
  // };

  const sendClipEmail = async () => {
    if (lastClip && Date.now() < lastClip + 30000) {
      toast('requestAlreadySent', 'warning');
      return;
    }
    setStatus('fetching');
    const r = await clipEmail();
    setStatus('done');
    if (r?.error) {
      toast('requestFailed', 'warning', { message: r.error });
      return;
    }
    setLastClip(Date?.now());
    toast('requestSent', 'success');
  };

  const awaitLogout = async () => {
    setStatus('fetching');
    await doLogout('menu');
    setStatus('done');
  };

  let component;
  if (noSubSignin && zone === 'assets')
    component = (
      <>
        {showStripePayment && (
          <StripePayment
            close={() => {
              setShowStripePaymemt(undefined);
            }}
            hideImage={true}
            type={showStripePayment}
            originatingGap={user?.gapid}
          />
        )}{' '}
        <NoSubMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          client={split[1]}
          messages={messages}
          doLogout={awaitLogout}
          defaults={defaults}
          openStripePaymentModal={openStripePaymentModal}
          data={data}
        />
      </>
    );
  else if (client === '' || client === undefined)
    component = (
      <R2RMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        client={split[1]}
        messages={messages}
        doLogout={awaitLogout}
        defaults={defaults}
      />
    );
  else if (client === 'auth')
    component = (
      <R2RMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        client={split[1]}
        messages={messages}
        doLogout={awaitLogout}
        defaults={defaults}
      />
    );
  else if (zone === '' || zone === undefined)
    component = (
      <RootMenu
        hasAssets={hasAssets}
        hasAccessProfiles={hasAccessProfiles}
        hasOemAdmin={hasOemAdmin}
        hasGapAdmin={hasGapAdmin}
        hasAdmin={hasAdmin}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        client={split[1]}
        messages={messages}
        doLogout={awaitLogout}
        defaults={defaults}
      />
    );
  else if (zone === 'access' && hasAccessProfiles && client !== 'r2r')
    component = (
      <AccessMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        client={split[1]}
        messages={messages}
        doLogout={awaitLogout}
        defaults={defaults}
      />
    );
  else if (zone === 'admin' && (hasAdmin || hasOemAdmin || hasGapAdmin))
    component = (
      <>
        {showAccount && (
          <MyAccountModal
            close={closeMyAccount}
            data={myAccountData!}
            fromTable={fromTable}
          />
        )}
        <AdminMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          hasAdmin={hasAdmin}
          hasOemAdmin={hasOemAdmin}
          hasGapAdmin={hasGapAdmin}
          hasClientAdmin={hasClientAdmin}
          client={split[1]}
          messages={messages}
          doLogout={awaitLogout}
          defaults={defaults}
          user={user}
        />
      </>
    );
  else if (zone === 'assets' && hasAssets && client !== 'r2r')
    component = (
      <>
        {showModal && (
          <AssetMenuModals
            type={modalType}
            close={closeModal}
            client={client}
            sendClipEmail={sendClipEmail}
          />
        )}
        {showAccount && (
          <MyAccountModal
            close={closeMyAccount}
            data={myAccountData!}
            fromTable={fromTable}
          />
        )}
        {showStripePayment && (
          <StripePayment
            close={() => {
              setShowStripePaymemt(undefined);
            }}
            hideImage={true}
            type={showStripePayment}
          />
        )}
        <AssetsMenu
          client={split[1]}
          defaults={defaults}
          doLogout={awaitLogout}
          languageId={languageId}
          languages={languages}
          messages={messages}
          openModal={openModal}
          openMyAccount={() => openMyAccount(user.email, store.token)}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          openStripePaymentModal={openStripePaymentModal}
          data={data}
        />
      </>
    );
  else if (zone === 'administrator' && hasClientAdmin && client !== 'r2r')
    component = (
      <>
        {showAccount && (
          <MyAccountModal
            close={closeMyAccount}
            data={myAccountData!}
            fromTable={fromTable}
          />
        )}
        <AdministratorMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          client={split[1]}
          messages={messages}
          doLogout={awaitLogout}
          defaults={defaults}
          user={user}
        />
      </>
    );
  else if (zone === 'support' && hasSupport && client !== 'r2r')
    component = (
      <>
        {showAccount && (
          <MyAccountModal
            close={closeMyAccount}
            data={myAccountData!}
            fromTable={fromTable}
          />
        )}
        <SupportMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          client={split[1]}
          messages={messages}
          doLogout={awaitLogout}
          defaults={defaults}
          hasUsersData={hasUsersData}
          hasPortalAccess={hasPortalAccess}
          hasOemAdmin={hasOemAdmin}
        />
      </>
    );
  else
    component = (
      <ul>
        {' '}
        <li>
          <Icon className={'fas fa-sign-out'} />
          &nbsp;&nbsp;
          <Link
            $client={client}
            $data={defaults}
            onClick={awaitLogout}
            to={'#'}
          >
            {messages.logout}
          </Link>
        </li>
      </ul>
    );
  return (
    <>
      {status === 'fetching' && <FSLoader which="clip email" />}
      <Container ref={ref} $show={showMenu}>
        {component}
      </Container>
    </>
  );
}

export default Menu;
