import styled from 'styled-components';
import Color from 'color';

import isIE from 'utils/isIE';

export const QuickLinksWrapper = styled.div`
  margin: 20px 0;
`;

export const Heading = styled.h2`
  color: black;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
`;

export const TilesWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-left: 20%;
  margin-right: 20%;
`;

export const Tile = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const TileImage = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  // content fit
  object-fit: contain;
`;

export const TileLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;
