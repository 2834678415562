import styled from 'styled-components';

export const Container = styled.div`
  padding: 2em;
  margin: auto;
  /* maxwidth: 1100px; */
`;

// Styled image with width
export const StyledImage = styled.img`
  width: 40rem;
`;
