import { useHistory, useParams } from 'react-router';
import { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { toast as toastify, TypeOptions } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { isToday } from 'date-fns';
import { debounce } from 'lodash';

// import { useSupabase } from 'utils/supabase';
import { supabase as sBase } from 'utils/supabase';
import { LanguageContext } from 'components/Language/Context';
import { APIContext } from 'components/API/Context';
import { AppContext } from 'components/App/Context';
import config from '../config';
import { useOEMList, useOEMUserList } from 'features/page/hooks';
import { authState, configtState } from './atoms';
import { OEM, OEMList, User } from 'types';
import {
  userFetch,
  session,
  accessFetch,
  sessionValidate,
  logoutSession,
} from 'utils/apis';

let _configDone = {};

export function useConfig() {
  // const [data, setDataX] = useState<any>();
  const [data, setDataX] = useRecoilState<any>(configtState);
  const { data: oems }: { data: OEMList[] | undefined } = useOEMList();
  const client = window.location.pathname.split('/')[1];

  const setData: Function = useCallback(
    (data) => {
      sessionStorage.setItem('defaults', JSON.stringify(data));
      setDataX(data);
    },
    [setDataX]
  );

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('defaults') || '{}');
    setDataX(data);
  }, [setDataX]);

  useEffect(() => {
    if ((!client && data?.client === 'r2r') || !oems) return;
    if (!client) {
      setData({ client: 'r2r' });
      return;
    }
    if (!oems) return;
    if (data?.client === client && _configDone[client]) return;
    const d = oems?.find(({ path }) => path === client);
    console.log('HERE ::::', { client, d, oems });
    const { url, key } = config;
    const carouselList: any[] = [];
    const p: any = d;
    if (p) {
      for (const [key, value] of Object.entries(p)) {
        if (key.startsWith('websitecarousel') && value) {
          carouselList.push(value);
        }
      }
      carouselList.reverse();
    }

    const defaults = {
      oemid: d?.oemid,
      divisionid: d?.divisionid,
      brandid: d?.brandid,
      websitelogo: d?.websitelogo,
      Name: d?.Name,
      fontcolour: d?.fontcolour,
      backgroundcolour: d?.backgroundcolour,
      primarycolour: d?.primarycolour,
      secondarycolour: d?.secondarycolour,
      faviconurl: d?.faviconurl,
      carouselList: carouselList,
      launchContent: d?.launch_content,
      loginmodalimg: d?.loginmodalimg,
      popuplogourl: d?.popuplogourl,
      resultsfromvinlookup: d?.resultsfromvinlookup,
      'help | faq': d?.Help_FAQ_pdf,
      help: d?.Help_FAQ_pdf,
      news: d?.assetsmenu_news_pdf,
      'Contact Us': d?.footer_contactus,
      'Privacy Policy': d?.footer_privacy_policy,
      'Legal Policy': d?.footer_legal_policy,
      'Cookie Policy': d?.footer_cookie_policy,
      'Terms And Conditions': d?.footer_terms_and_conditions,
      FAQ: d?.Help_FAQ_pdf,
      Links: d?.footer_link,
      footerLabels: d?.footer_labels,
      headerOemName: d?.header_oem_name,
      oemHomeUrl: d?.oem_homepage_url,
      footerOemName: d?.footer_oem_name,
      signuponlybusiness: d?.signuponlybusiness,
      signuponlyindividual: d?.signuponlyindividual,
      launchcontenttest: d?.launchcontenttest,
      clipInstructions: d?.clip_instructions_pdf,
      oemadminconfig: d?.oemadminconfig,
      signinnosub: d?.signinnosub,
      canDownloadAssets: d?.canDownloadAssets,
      devEnabled: d?.devEnabled,
      path: d?.path,
    };

    _configDone[client] = true;
    setData({ url, key, defaults, client });
  }, [oems, client, setData, data]);

  const getDefaults = () => data?.defaults;
  // // console.log('defaultsXXXXXXX:::', {
  //   data,
  // });

  return { ...data, getDefaults };
}

let _expires = false;
let _nosub = false;

export function useSessionExpiry(doLogout: Function) {
  const { toast } = useToast();
  const expires: any = useRef();
  const history = useHistory();
  const { noSubSignin } = useContext(AppContext);

  const expireLogin = debounce(async (where = '') => {
    console.log('EXPIRE LOGIN ::::', _expires, where);
    if (_expires || expires.current) return;
    _expires = true;
    await expireLoginX(where);
  }, 500);

  const expireLoginX = useCallback(
    async (where = '') => {
      try {
        if (!expires.current)
          expires.current = setInterval(() => expireLoginX(where), 1000 * 30);
        const loginTime: any = sessionStorage.getItem('login_time');
        if (!loginTime) {
          // _expires = false;
          return;
        }
        const toTime = parseInt(loginTime) + 36000 * 1000;
        const auth: any = sessionStorage.getItem('auth');
        const store = JSON.parse(auth || '{}');
        if (!store?.aasraAuthed) {
          console.log('not aasra authed yet....');
          return;
        }
        if ((!store.token || !store?.user?.nosub) && !noSubSignin) {
          return;
        }
        const session = noSubSignin
          ? { error: '', data: {} }
          : await sessionValidate(store?.user?.email, store.token);
        console.log('session :::', where, session?.data, store.token);
        if (
          Date.now() > toTime ||
          session?.error ||
          session?.data?.status === 'failed'
        ) {
          console.log('session expiry', toTime, Date.now(), session);
          doLogout('expireloginx').then((error: any) => {
            const client = window.location.pathname.split('/')[1];
            // _expires = false;
            history.push(`/${client}`);
            console.log('loggedout :::', client);
            if (error && isToday(store?.user?.start)) {
              toast(
                session?.data?.status === 'failed'
                  ? 'invalidSession'
                  : 'sessionExpired',
                'warning'
              );
            }
          });
        } else {
          // expires.current = setTimeout(expireLoginX, 1000 * 30);
          console.log('checked session expiry....', {
            to: toTime,
            now: Date.now(),
          });
        }
      } catch (error) {
        console.log('ERROR expire session :::::', error);
      }
    },
    [doLogout, history, toast, noSubSignin]
  );

  useEffect(() => {
    if (noSubSignin && !_nosub) {
      expireLoginX('noSub');
      _nosub = true;
    }
    /* eslint-disable-next-line */
  }, [noSubSignin]);

  return {
    expireLogin,
  };
}

let _accessFetch = false;

export function useAuth() {
  const { defaults } = useConfig();

  const authorise = async (user: string, gapid?: number) => {
    if (!defaults) return { error: 'No client (1)' };
    if (!user) return { error: 'No user' };
    if (_accessFetch) return { error: 'In progress' };
    const client = window.location.pathname.split('/')[1];
    if (!client) return { error: 'No client (2)' };
    _accessFetch = true;
    const authed = await accessFetch(user, client, defaults.oemid, gapid);
    _accessFetch = false;
    console.log({ authed });
    if (authed?.data?.error) return { error: authed?.data?.error };
    return authed?.data;
  };

  return { authorise };
}

export function useClient() {
  const location = window.location;
  const split = location.pathname.split('/');
  const client = split[1];
  return { client, split };
}

let _loggingOut = false;

export function useLoggedIn(noRedirect?: boolean, bail?: boolean) {
  const history = useHistory();
  const [auth, setAuth] = useRecoilState<any>(authState);
  const { reset, prefetch } = useContext(APIContext);
  const { setUser, setNoSubSignin } = useContext(AppContext);
  // const supabase = useSupabase()
  const supabase = sBase();
  const { authorise } = useAuth();
  const { fetchUser, list } = useOEMUserList();
  const { defaults } = useConfig();

  const doLogout = async (where = 'none') => {
    if (_loggingOut) return;
    _loggingOut = true;
    await logoutSession(); // logout
    const { error } = await supabase.auth.signOut();
    await reset();
    window.sessionStorage.removeItem('login_time');
    window.sessionStorage.removeItem('client');
    window.sessionStorage.removeItem('auth');
    window.sessionStorage.removeItem('supabase.auth.token');
    _expires = false;
    console.log('logout ::::', error, where);
    setTimeout(() => {
      setUser(undefined);
      setAuth({ user: null, status: 'done' });
      setNoSubSignin(false);
      _loggingOut = false;
    }, 100);
    logout();
    return error;
  };

  const { expireLogin } = useSessionExpiry(doLogout);

  const setSessionToken = async (
    email: string,
    gapid: number = 1,
    subscription: any[],
    accesstoken: string
  ): Promise<any> => {
    const token: string = v4?.() || 'sdf08hsd9fasdfh89asfh';
    console.log(email, gapid, subscription, token);
    const resp: any = await session(
      email,
      token,
      gapid,
      subscription,
      accesstoken
    );
    if (resp.error) return { error: resp.error };
    if (resp?.data?.status !== 'success') return { error: 'Unknown error' };
    return { token };
  };

  const refreshLogin = useCallback(
    async (noAuth = false) => {
      // console.log('defaults2222 :::', defaults);
      const cli = window.location.pathname.split('/')[1];
      if (auth?.status === 'done') return;
      if (!cli) {
        setAuth({ status: 'done', aasraRoles: auth?.aasraRoles });
        return;
      }
      if (!defaults && cli) return;
      const authx: any = sessionStorage.getItem('auth');
      const store = JSON.parse(authx || '{}');
      const au = await authorise(store?.user?.email, store?.user?.gapid);
      // console.log('refreshLogin ***', cli, defaults, auth.status);
      if (!noAuth && au?.error && au?.error !== 'In progress') {
        setAuth({ status: 'done', aasraRoles: auth?.aasraRoles });
        return false;
      }
      if (store?.user?.email && au?.error !== 'In progress') {
        // console.log('refreshLogin 2 ***', au, store?.user?.email);
        // await list(store?.user?.email);
        const data = await fetchUser(store?.user?.email, store?.user?.gapid);
        setUser(store?.user?.email);
        // console.log('refreshLogin 3 ***', data);
        if (
          data?.subscription?.length &&
          data?.subscription?.find((x) => x.isvalid)
        ) {
          prefetch().then(() => {});
        }
        // expireLogin();
        setAuth({
          ...store,
          aasraRoles: auth?.aasraRoles || null,
          status: 'done',
        });
      }
      return true;
    },
    /* eslint-disable-next-line */
    [setAuth, authorise, defaults, auth]
  );

  const logout = () => {
    setAuth({});
    // history?.push?.('/');
  };

  const OTPFetch = async (phone: string) => {
    const { user, error } = await supabase.auth.signIn({
      phone,
    });
    return { error };
  };

  const OTPConfirm = async (phone: string, token: string) => {
    const { user, error } = await supabase.auth.verifyOTP(
      {
        phone,
        token,
      },
      { redirectTo: undefined }
    );
    console.log('OTP CONFIRM :::::', user, error);
    return {
      user,
      error:
        error && !error.message?.includes('Network request') ? error : null,
    };
  };

  const OTPSuccess = async (
    user: any,
    username: string,
    client: string,
    gapid: number,
    accesstoken: string
  ) => {
    const dets: (User & { subscription: any[] }) | undefined = await fetchUser(
      username,
      gapid
    );
    if (!dets) return { message: 'No user found' };
    window.sessionStorage.setItem('client', client);
    window.sessionStorage.setItem('login_time', Date.now().toString());
    const { firstname, surname, mobilenumber, subscription } = dets || {};
    const { token, error } = await setSessionToken(
      username,
      gapid,
      subscription,
      accesstoken
    );
    if (error) return { message: error };
    const nosub = subscription?.length && subscription?.find((x) => x.isvalid);
    const store = {
      user: {
        email: username,
        firstname,
        surname,
        mobilenumber,
        id: user?.id,
        start: Date.now(),
        gapid,
        nosub,
      },
      token,
      status: 'done',
    };
    setAuth(store);
    window.sessionStorage.setItem('auth', JSON.stringify(store));
    if (nosub) {
      setTimeout(async () => await prefetch(), 1000);
    }

    expireLogin('OTP');
  };

  const doEmailLogin = async (
    username: string,
    password: string,
    client: string,
    gapid: number,
    accesstoken: string
  ) => {
    const dets: (User & { subscription: any[] }) | undefined = await fetchUser(
      username,
      gapid
    );
    console.log('dets ::::', dets, gapid);
    if (!dets) return { message: 'No user found' };
    // if (!dets?.subscription?.length && client !== 'r2r')
    //   return { message: 'No subscription' };
    const { user, error } = await supabase.auth.signIn({
      email: username,
      password: password,
    });
    if (error) return error;
    window.sessionStorage.setItem('client', client);
    window.sessionStorage.setItem('login_time', Date.now().toString());
    const { firstname, surname, mobilenumber, subscription } = dets || {};
    const { token, error: sessionError } = await setSessionToken(
      username,
      gapid,
      subscription,
      accesstoken
    );
    if (sessionError) return { message: sessionError };
    const nosub = subscription?.length && subscription?.find((x) => x.isvalid);
    const store = {
      user: {
        email: username,
        firstname,
        surname,
        mobilenumber,
        id: user?.id,
        start: Date.now(),
        gapid,
        nosub,
      },
      token,
      status: 'done',
    };
    console.log('dets2 :::', store);
    setAuth(store);
    window.sessionStorage.setItem('auth', JSON.stringify(store));
    if (nosub) {
      setTimeout(async () => await prefetch(), 1000);
    }
    expireLogin('email');
    return error;
  };

  const doMagicLogin = async (username: string) => {
    await supabase.auth.signIn({
      email: username,
    });
  };

  const setPassword = async (accessToken: string, password: string) => {
    const { error, data } = await supabase.auth.api.updateUser(accessToken, {
      password,
    });
    return error;
  };

  const setAASRARole = (aasraRoles: any[]) => {
    if (auth.aasraRoles) return;
    const d = window.sessionStorage.getItem('auth');
    const store = d ? JSON.parse(d) : {};
    store.aasraAuthed = true;
    window.sessionStorage.setItem('auth', JSON.stringify(store));
    console.log('SETROLES :::', auth, aasraRoles);
    setAuth({ ...auth, aasraRoles });
  };

  return {
    user: auth.user ?? null,
    status: auth.status ?? null,
    aasraRoles: auth.aasraRoles ?? null,
    setAASRARole,
    logout,
    refreshLogin,
    doEmailLogin,
    doLogout,
    setPassword,
    doMagicLogin,
    OTPFetch,
    OTPConfirm,
    OTPSuccess,
  };
}

export function useAdminNav() {
  const { client }: any = useParams();
  const history = useHistory();

  const nav = (to: string, state?: any) => {
    history.push(`/${client}/admin/maintenance${to}`, state);
  };

  return { push: nav };
}

export function useAccessNav() {
  const { client }: any = useParams();
  const history = useHistory();

  const nav = (to: string, state?: any) => {
    history.push(`/${client}/access/maintenance${to}`, state);
  };

  return { push: nav };
}

export function useAssetsNav() {
  const history = useHistory();

  const nav = (client: string, to: any) => {
    history.push(`/${client}/assets/find/${to}`);
  };

  return { push: nav };
}

export function useBulletinsNav() {
  const history = useHistory();

  const nav = (client: string, to: any, which?: string) => {
    history.push(`/${client}/assets/${which || 'bulletins'}/${to}`);
  };

  return { push: nav };
}

export function useToast(): {
  toast: (msg: string, type: TypeOptions, values?: any) => void;
} {
  const [msgs, setMsgs] = useState<string[]>([]);

  const toast = (msg: string, type: TypeOptions, values?: any) => {
    if (msgs.indexOf(msg) >= 0) return;
    const m = msgs.slice();
    m.push(msg);
    setMsgs(m);
    toastify(<FormattedMessage values={values} id={msg} />, { type });
    if (process.env.NODE_ENV === 'test') return;
    setTimeout(() => {
      const m = msgs.slice();
      const index = m.findIndex((m) => m === msg);
      m.splice(index, 1);
      setMsgs(m);
    }, 5000);
  };
  return { toast };
}

export function useLongToast(): {
  longToast: (msg: string, type: TypeOptions) => void;
} {
  const longToast = (msg: string, type: TypeOptions) => {
    toastify(msg, {
      position: 'top-right',
      autoClose: 10000,
      pauseOnHover: true,
      type,
    });
  };
  return { longToast };
}

export function useLanguage(languages: any) {
  const applyDesc = (data: any) => {
    return data?.map((oem) => {
      return {
        ...oem,
        languagedescription: languages?.find(
          (l) => l.languageid === oem.languageid
        )?.languagedescription,
      };
    }) as any;
  };

  return {
    applyDesc,
  };
}

export function useLanguages() {
  const {
    messages,
    languageId,
    locales: languages,
  } = useContext(LanguageContext);

  return { messages, languageId, languages };
}

export function useNavigator() {
  const [userAgentStatus, setUserAgentStatus] = useState<any>(null);
  const [isIE, setIsIE] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setUserAgentStatus(userAgent);
    setIsIE(userAgent.indexOf('Trident') > 0);
  }, [userAgentStatus]);

  return { userAgentStatus, isIE };
}

export function useAnalytics() {
  const sb = sBase();
  const { defaults } = useConfig();
  const { user } = useLoggedIn();
  const auth: any = sessionStorage.getItem('auth');
  const { user: authUser, token } = JSON.parse(auth || '{}');
  const { aasra: userAasra } = useOEMUserList();

  const isDev =
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('dev');

  const getAasraProfile = async () => {
    if (!userAasra) return null;

    let aasraProfile: any[] = [];
    if (!userAasra[0]['aasraprofiledescriptionshort']) {
      const res = await sb
        .from('tb_aasraprofile_id')
        .select('*')
        .eq('oemid', defaults?.oemid);

      if (!res.data) return;
      const userProfileIds: any[] = userAasra.map((p) => p?.['aasraprofileid']);
      aasraProfile = res.data
        ?.filter((p) => userProfileIds.includes(p?.['aasraprofileid']))
        .map((x) => x?.aasraprofiledescriptionshort);
    } else {
      userAasra.forEach((p) =>
        aasraProfile.push(p['aasraprofiledescriptionshort'])
      );
    }

    return aasraProfile.join(', ');
  };

  const asset = async (assetid: number | null) => {
    if (!assetid || isDev) return;
    await sb.from('tb_analytics').insert({
      action: 'view_asset',
      userid: user?.email,
      oemid: defaults?.oemid,
      assetid,
      aasraprofile: await getAasraProfile(),
      sessiontoken: token,
      gapid: authUser?.gapid,
      subscriptionid: authUser?.nosub?.subscriptionid,
    });
  };

  const tsr = async (tsrid: number | null) => {
    if (!tsrid || isDev) return;
    await sb.from('tb_analytics').insert({
      action: 'view_tsr',
      userid: user?.email,
      oemid: defaults?.oemid,
      tsrid,
      aasraprofile: await getAasraProfile(),
      sessiontoken: token,
      gapid: authUser?.gapid,
      subscriptionid: authUser?.nosub?.subscriptionid,
    });
  };

  const bulletin = async (bulletinid: number | null) => {
    if (!bulletinid || isDev) return;
    await sb.from('tb_analytics').insert({
      action: 'view_bulletin',
      userid: user?.email,
      oemid: defaults?.oemid,
      bulletinid,
      aasraprofile: await getAasraProfile(),
      sessiontoken: token,
      gapid: authUser?.gapid,
      subscriptionid: authUser?.nosub?.subscriptionid,
    });
  };

  const menu = async (
    menuitem: string | null,
    assetcategoryid: number | undefined
  ) => {
    if (!menuitem || isDev) return;
    await sb.from('tb_analytics').insert({
      action: !assetcategoryid ? 'view_menu' : 'view_category_menu',
      userid: user?.email,
      oemid: defaults?.oemid,
      menuitem: menuitem || null,
      assetcategoryid: assetcategoryid || null,
      aasraprofile: await getAasraProfile(),
      sessiontoken: token,
      gapid: authUser?.gapid,
      subscriptionid: authUser?.nosub?.subscriptionid,
    });
  };

  return { asset, menu, tsr, bulletin };
}
