import { memo } from 'react';
import { isEmpty } from 'lodash';

import * as S from './styled';
import './LaunchContent.css';

function LaunchContent({ launchContent }: { launchContent: string }) {
  const defaultContent = `
  <article role="article">
    <div>
      <div>
        <section class="body-content d-flex flex-column">
          <div class="home-page" >
   <p><b>Background</b></p>
            <p>Inchcape Automotive Australia Pty Ltd trading as DEEPAL Australia (“DEEPAL Australia”) is the exclusive authorised importer and distributor of new DEEPAL branded motor vehicles in Australia.  As such, DEEPAL Australia is deemed to be a data provider for the purposes of the Motor Vehicle Information Scheme (MVIS) which is effective as of 1 July 2022.</p><br/>
            <p>DEEPAL Australia primarily seeks to meet its obligations under the MVIS via this website.</p><br/>

            <p><b>What is the motor vehicle information scheme (MVIS)?</b></p>
            <p>The Motor Vehicle Information Scheme (MVIS) is a scheme set up under the Competition and Consumer Amendment (Motor Vehicle Service and Repair Information Sharing Scheme) Act 2021, pursuant to which motor vehicle manufacturers and distributors are required to make certain repair and service information available to certain persons at a fair market price.</p><br/>
            <p>As the importer and distributor of DEEPAL branded motor vehicles in Australia, DEEPAL Australia is subject to the MVIS which is intended to promote competition in the Australian automotive sector and establish a level playing field for all repairers.  DEEPAL Australia is committed to meeting its obligations under the MVIS, primarily via this website.</p><br/>
            <p>You can find out more about the MVIS by visiting <a style="color: blue;" href="https://www.accc.gov.au/focus-areas/motor-vehicle-information-scheme-mvis">https://www.accc.gov.au/focus-areas/motor-vehicle-information-scheme-mvis</a></p><br/>

            <p><b>What service and repair information is available on this website?</b></p>
            <p>On this website you can access the following information for DEEPAL motor vehicles from Model Year (MY) 2024 onwards:</p>
            <br>
            <p><b>Service Manuals (including wiring diagrams, technical specifications for components and lubricants and testing procedures, information and codes for computerised systems, information such as the steps involved in doing a scheduled service or technical specifications for components and lubricants and testing procedures, even where it is contained in a log book), Body Repair Manuals, Technical Service Bulletins, Recall Campaigns Bulletins, Software updates and Key codes.</b></p><br/>

            <p><b>How can I access DEEPAL service and repair information?</b></p>
            <p>For MY2024 onwards, you can instantly access service and repair information by “subscribing” to the relevant information via this website. Subscriptions can be purchased for 1 day, 1 month or 12 months.</p><br/>
            <p>Under the MVIS, DEEPAL Australia is entitled to charge a fair market price for access to the information.</p><br/>

            <p><b>Safety and Security Information and Fit and Proper Person test</b></p>
            <p>The MVIS requires DEEPAL Australia to make certain security and safety related repair and service information available only to “fit and proper persons” (as prescribed by the MVIS rules).  As such, where you subscribe for access to such information you will be required to provide certain additional information in order to allow DEEPAL Australia to make this assessment.  DEEPAL Australia will be unable to provide access to the information in the event you cannot provide the additional information or are assessed not to be a fit and proper person.</p><br/>
            <p>In order to obtain a fit and proper person check, you will need to create an AASRA account through the AASRA website (<a style="color: blue;" href="https://aasra.com.au/">https://aasra.com.au/</a>) using the same email address that you will/have created an account for on our page.</p><br/>
            <p>What other terms and conditions apply to my access of the service and repair information?</p><br/>

            <ol style="list-style-type: decimal; margin-left: 4rem;">
              <li><span style='font-size:15px;'>The service and repair information has been drafted for use solely by suitably trained and qualified technicians.  You should not proceed to attempt a repair or service if you do not understand any part of the information and/or do not have suitable tools.</span></li>
              <li><span style='font-size:15px;'>You should carefully consider and abide by all warnings and cautions contained within the repair and service information.</span></li>
              <li><span style='font-size:15px;'>Copyright in the majority of the service and repair information is owned by CHANGAN Automobile, China.  Your access to the information is intended purely to repair or service a relevant DEEPAL motor vehicle in Australia and for no other purpose.  Copying or reproducing the information without consent is prohibited and a breach of the law.</span></li>
              <li><span style='font-size:15px;'>Repair and service information contained on, or accessed via this website may be updated from time to time.  You should always subscribe for access to the latest repair and service information when servicing or repairing a DEEPAL motor vehicle.</span></li>
              <li><span style='font-size:15px;'>If you are a motor mechanic accessing service information for the purposes of servicing a DEEPAL motor vehicle for your customer, by accessing information via this website, you agree to sign, date, and stamp the service and warranty handbook (where indicated) that is located with Owner’s Manual wallet.</span></li>
            </ol>
            <br/>
<p><b>Pricing (inclusive of GST)</b></p><br/>
<p><b>Service and Repair Information Access Fees</b></p><br/>
<img src="/DeepalSubOptions.png" style="width:60rem">

<br/>

                <br />
<p><b>Key Codes</b> $49 per key code</p><br/>
<p><b>Diagnostic Software Fees</b></p><br/>
<p>A DEEPAL diagnostic subscription allows the user to download the diagnostic software. Refer to this document for PC / laptop and supported operating system requirements.</p><br/>
<img src="/DeepalSoftwareOptions.png" style="width:50rem">

<p><b>Diagnostic Hardware price</b></p><br/>
<img src="/DeepalHardwareOptions.png" style="width:30rem">



                <br />


<p><b>Contact Us</b></p><br/>
<p>If you have any questions regarding this website or your subscription, email us at <a style="color: blue;" href="mailto:deepal.support@right-2-repair.com.au"><b>deepal.support@right-2-repair.com.au</b></a>.</p><br/>
          </div>
        </section>
    </div>
  </div>
</article>
`;
  const contentMarkup = () => {
    return { __html: !isEmpty(launchContent) ? launchContent : defaultContent };
  };

  return (
    <S.Container>
      <div dangerouslySetInnerHTML={contentMarkup()}></div>
    </S.Container>
  );
}

export default memo(LaunchContent);
